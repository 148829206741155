/* responsive ******************************************************************************/
@media (max-width: 1599px) {

  
}

/* responsive ******************************************************************************/
@media (max-width: 1399px) {
  #chatSection .user-chat-nav .user-chat-box a .user-info h5 {
    font-size: 18px;
}

 .chat-box-right .contact-profile h5 {
      font-size: 18px;
  }
  .sticker {
    width: 210px;
}
.sticker .user-data {
  margin-right: 5px;
}
.sticker .img-box {
  width: 40%;
}
.sticker .user-data span.type {
  font-size: 10px;
}
.sticker p:not(.amount) {
  font-size: 13px;
}
.sticker p.amount {
  font-size: 16px;
}
#chatSection .chat-box-right .img_cont_msg > img {
  max-width: 85%;
}
}

/* responsive ******************************************************************************/
@media (max-width: 1199px) {
  #chatSection .user-chat-nav .user-chat-box a .user-info h5 {
    font-size: 16px;
}
#chatSection .user-chat-nav .user-chat-box a .user-info p {
  font-size: 14px;
  line-height: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 10px;
  font-weight: 400;
}

   .chat-box-right .contact-profile h5 {
    font-size: 16px;
}
#chatSection .chat-box-right .message .msg_cotainer {
  font-size: 15px;
  padding: 8px 15px 8px 10px;
}
#chatSection .chat-box-right .message .msg_cotainer_send{
  font-size: 15px;
  padding: 8px 15px 8px 10px;
}
#chatSection .chat-box-right .img_cont_msg > img {
  max-width: 75%;
}
#chatSection .user-chat-nav  .nav-link {
  padding: 5px;
}
}

/* responsive ******************************************************************************/
@media (max-width: 1024px) {

}

/* responsive ******************************************************************************/
@media (max-width: 991px) {

     /* Login page  */
     .main-wrapper .login-box  .card  .logo-box img {
      max-width: 80%;
  }
  .main-wrapper .login-box .card .card-title h3 {
      font-size: 28px;
  }

  .sticker .user-data span.type {
    margin-bottom: 5px;
}
#chatSection .user-chat-nav .user-chat-box a .img-box {
  width: 41px;
  height: 41px;
  max-width: 100%;
}
#chatSection .user-chat-nav .user-chat-box .time {
  font-size: 10px;
}
#chatSection .user-chat-nav .user-chat-box a .user-info p {
  font-size: 12px;
}
#chatSection .user-chat-nav .chat-time span {
  height: 18px;
  width: 18px;
  line-height: 18px;
}
.dwn-litechat-inner .text-box h3 {
  font-size: 26px;
}
.dwn-litechat-wrapper .text-box p {
  width: 100%;
  font-size: 16px;
}
.dwn-litechat-wrapper .button-box img {
  max-width: 80%;
}
.encryped-text  span {
  font-size: 12px;
}
}

/* responsive ******************************************************************************/
@media (max-width: 767px) {
  .sticker {
    width: 180px;
}
#chatSection .chat-box-right{
  display: none;
}
#chatSection .chat-box-right.mobile-chat {
  display: block;
}
}

/* responsive ******************************************************************************/
@media (max-width: 575px) {
    .main-wrapper .login-box  .card  .logo-box img {
        max-width: 70%;
    }
    .main-wrapper .login-box  .card .card-body {
        margin-top: 0px;
    }
}

/* responsive ******************************************************************************/
@media (max-width: 479px) {
  .main-wrapper .login-box .form-group {
    margin-bottom: 10px;
}
.main-wrapper .login-box .card .card-title h3 {
    font-size: 21px;
}
.main-wrapper .login-box  .card .card-title p {
    font-size: 15px;
}

  #chatSection .chat-box-right .msg_card_body ,
  #chatSection .chat-box-right .contact-profile,
  #chatSection .chat-box-right .box-footer {
    padding: 15px 10px;
}

  #chatSection .chat-box-right .contact-profile .call-setting-tab > .btn {
    height: 35px;
    width: 35px;
}
.chat-box-right .contact-profile .call-setting-tab > .btn > i {
  font-size: 13px;
}
#chatSection .chat-box-right .message {
  max-width: 80%;
}
}
/* responsive ******************************************************************************/
@media (max-width: 375px) {
  /* Chat box right  */
  .chat-box-right .contact-profile h5 {
    font-size: 15px;
}
}
/* responsive ******************************************************************************/
@media (max-width: 320px) {

     
  .main-wrapper .login-box .card .logo-box img {
    max-width: 60%;
}
.main-wrapper .login-box .card .card-title h3 {
    font-size: 21px;
}
.main-wrapper .login-box  .card .card-title p {
    font-size: 15px;
}
.main-wrapper .login-box .card .card-body {
    margin-top: 0px;
}
.main-wrapper .login-box .form-group {
    margin-bottom: 20px;
}

  .sticker {
    width: 140px;
}
.sticker .user-data span.type {
  font-size: 8px;
}
.sticker p:not(.amount) {
  font-size: 10px;
}
.sticker p.amount {
  font-size: 12px;
}
}
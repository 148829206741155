.drawer {
    position: fixed;
    top: 0;
    left: 0;
    width: 475px;
    height: 100vh;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    overflow-y: auto;
    z-index: 1000;
    padding: 0;
  }
.drawer-right {
    position: fixed;
    top: 0;
    right: 0;
    width: 475px;
    height: 100vh;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    overflow-y: auto;
    z-index: 1000;
    padding: 0;
  }
  
  .drawer.open {
    transform: translateX(0);
  }
  .drawer-right.open {
    transform: translateX(0);
  }
  
  .drawer-toggle {
    position: fixed;
    top: 20px;
    left: 20px;
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    z-index: 1100;
  }
  .drawer-toggle-right {
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    z-index: 1100;
  }
  
  .drawer-toggle:focus {
    outline: none;
  }
  